(function ( $ ) {

    $.fn.serializeForm = function(ignore) {
        let unindexed_array = $(this).serializeArray();
        let indexed_array = {};

        $.map(unindexed_array, function(n, i){
            if (ignore && n['name'].match(ignore)) {
                return;
            }
            indexed_array[n['name']] = n['value'];
        });

        return indexed_array;
    };

}( jQuery ));
