import VlStripe from "./kompo/VlStripe";
import VlModal from "./kompo/VlModal";
import VlSection from "./kompo/VlSection";
import VlSelectUpdatable from "./kompo/VlSelectUpdatable";
import Alert from "vue-kompo/js/core/Alert";
import Vue from 'vue';
import Vuex from 'vuex';
import FileManager from './kompo/FileManager/init'
import VlAlbum from "./kompo/VlAlbum";
import VlVerticalTabs from "./kompo/VlVerticalTabs";
import VlVerticalFormTabs from "./kompo/VlVerticalFormTabs";
import TinyMCEditor from "./kompo/TinyMCEditor/TinyMCEditor";
import VlSelectList from "./kompo/VlSelectList";
import VlAlbumThumbnail from "./kompo/VlAlbumThumbnail";
import VlAlbumThumbnailGallery from "./kompo/VlAlbumThumbnailGallery";

window._ = require('lodash');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.interceptors.response.use(function (response) {
    if (response.status === 206 && response.headers.location) {
        $.pjax({url: response.headers.location, container: '#pjax-container'});
        return response;
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

window.Vue = Vue;
require('./kompo/index')

Vue.use(Vuex);

const store = new Vuex.Store();
Vue.use(FileManager, {
    store: store
});

// add support for modal closing without ajax submits
const VlEditLinkModalContent = Vue.options.components["VlEditLinkModalContent"].extend({
    methods: {
        success(response, submitKomponent) {
            this.$emit('refresh', this.index)

            if (!this.keepModalOpen && (!submitKomponent || !submitKomponent.$_keepModalOpen))
                this.$emit('closeModal')
        }
    }
});
Vue.component('VlEditLinkModalContent', VlEditLinkModalContent);

// Trigger events on initial load of select buttons for default value
const VlSelectButtons = Vue.options.components["VlSelectButtons"].extend({
    methods: {
        $_setInitialValue() {
            this.component.options.forEach((option, key) => {
                option.selected = option.value == this.component.value ? true : false
            })

            setTimeout( () => {
                this.$_changeAction()
            }, 100);
        }
    }
});
Vue.component('VlSelectButtons', VlSelectButtons);

// Add timeout to properly handle custom cards in select
const VlSelect = Vue.options.components["VlSelect"].extend({
    methods: {
        select(option){
            if (this.isSelected(option)) {
                this.$_remove(this.indexOf(option));
                setTimeout(() => {
                    this.reset()
                    this.$_blurAction()
                }, 100);
            } else {
                if (!this.$_multiple) {
                    this.$_remove(this.indexOf(option))
                }
                setTimeout(() => {
                    this.add(option)
                    this.reset()
                    this.$_blurAction()
                }, 100)
            }
        },
    }
});
Vue.component('VlSelect', VlSelect);

// override validation alert message
const VlFormInner = Vue.options.components["VlFormInner"].extend({
    methods: {
        submitError(e){
            this.$emit('error', e)

            if (e.response.status == 422){
                this.$_validate(e.response.data.errors)
                new Alert('Bitte korrigieren Sie die Fehler').asError().emitFrom(this)
            }
        },
    }
});
Vue.component('VlFormInner', VlFormInner);

Vue.component('VlSelectUpdatable', VlSelectUpdatable);
Vue.component('VlStripe', VlStripe);
Vue.component('VlModal', VlModal);
Vue.component('VlSection', VlSection);
Vue.component('VlAlbum', VlAlbum);
Vue.component('VlVerticalTabs', VlVerticalTabs);
Vue.component('VlVerticalFormTabs', VlVerticalFormTabs);
Vue.component('VlTinyMCEditor', TinyMCEditor);
Vue.component('VlSelectList', VlSelectList);
Vue.component('VlAlbumThumbnail', VlAlbumThumbnail);
Vue.component('VlAlbumThumbnailGallery', VlAlbumThumbnailGallery);


window.kompoFormBack = function (object) {
    window.history.back();
}

$(document).on('pjax:success', function () {
    if ($('#kompo-app').length) {
        new Vue({el: '#kompo-app', store});
    }
})
