<template>
    <div class="fm-content d-flex flex-column">
        <disk-list v-bind:manager="manager"/>
        <breadcrumb v-bind:manager="manager"/>
        <div class="fm-content-body">
            <table-view v-if="viewType === 'table'" v-bind:manager="manager"/>
            <grid-view v-else v-bind:manager="manager"/>
        </div>
        <button v-show="showChooseDirectoryButton"
                @click="chooseDirectory"
                type="button"
                class="btn btn-md btn-primary float-right">
            <i class="fas fa-folder"/> Wählen ordner {{ selectedDirectoryName }}
        </button>
    </div>
</template>

<script>
// Components
import DiskList from './DiskList.vue';
import Breadcrumb from './Breadcrumb.vue';
import TableView from './TableView.vue';
import GridView from './GridView.vue';
import translate from "../../mixins/translate";

export default {
  name: 'Manager',
  mixins: [translate],
  components: {
    DiskList,
    Breadcrumb,
    TableView,
    GridView,
  },
  props: {
    manager: { type: String, required: true },
  },
  computed: {
    /**
     * view type - grid or table
     * @returns {default.computed.viewType|(function())|string}
     */
    viewType() {
      return this.$store.state.fm[this.manager].viewType;
    },
    showChooseDirectoryButton() {
        if (this.$store.state.fm.fileCallback) {
            return (this.$store.getters['fm/selectedItems'].length && this.$store.getters['fm/selectedItems'][0].type == 'dir') || this.$store.state.fm[this.manager].selectedDirectoryObject;
        }

        return false;
    },
    selectedDirectoryName() {
        if (this.$store.getters['fm/selectedItems'].length && this.$store.getters['fm/selectedItems'][0].type == 'dir') {
           return this.$store.getters['fm/selectedItems'][0].basename;
        }

        if (this.$store.state.fm[this.manager].selectedDirectoryObject) {
            return this.$store.state.fm[this.manager].selectedDirectoryObject.basename;
        }
    }
  },
  methods: {
    chooseDirectory() {
        if (this.$store.getters['fm/selectedItems'].length && this.$store.getters['fm/selectedItems'][0].type == 'dir') {
            this.$store.state.fm.fileCallback(this.$store.getters['fm/selectedItems'][0]);
            return;
        }

        this.$store.state.fm.fileCallback(this.$store.state.fm[this.manager].selectedDirectoryObject);
    }
  }
};
</script>

<style lang="scss">
    .fm-content {
        height: 100%;
        padding-left: 1rem;

        .fm-content-body {
            overflow: auto;
        }
    }
</style>
