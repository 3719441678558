const SELECTOR = '[data-widget="input-toggle"]';

export default class InputToggle {
  static init() {

      $(document).off('click', '.switch-input').on('click', '.switch-input', function (e) {
        let target = $(e.target).parents(SELECTOR).data('target');
        let old = $(target).attr('hidden');
        $(target).prop('checked', old !== 'hidden');
        InputToggle.toggleTarget(target, old === 'hidden');
      });

      $(SELECTOR).each(function (i, element) {
          let checked = $(element).data('active') ? 'checked' : '';
          let target = $(element).data('target');
         // $(element).parents('div').first().find('.help-block').hide();

          InputToggle.toggleTarget(target, checked === 'checked');

          let swtch = $(`
            <label class="switch switch-pill switch-primary ml-1 pt-3">
                <span class="switch-slider"></span>
            </label>
          `);

          let swtchCheckbox = $(`<input type="checkbox" class="switch-input" ${checked}>`);
          swtchCheckbox.prop('checked', checked);

          swtch.prepend(swtchCheckbox);

          $(element).append(swtch)
      });
  }

  static toggleTarget(target, status) {
      $(target).attr('hidden', ! status);
      $(target).attr('disabled', ! status).trigger('inputtoggle.change');

      if (status) {
        $(target).find('.help-block').show();
      } else {
        // $(target).parent().find('.help-block').hide();
      }
  }
}
