const SELECTOR = '[data-widget="adeditor"]';

let reloadPreviewTimeout = null;
function reloadPreview()
{
    clearTimeout(reloadPreviewTimeout);
    reloadPreviewTimeout = setTimeout(() => {
        let previewQuery = $(SELECTOR).parents('form').serializeForm(/^(location|day|target_filter).+/gm);
        previewQuery['company_id'] = window.environment.company_id;

        let iframe =window.location.protocol + '//' + window.location.host + '/ad/preview?' + $.param(previewQuery);
        $('#adPreviewIframe').attr('src', iframe);
    }, 500)
}

function resizeIFrameToFitContent(iFrame) {
    iFrame.height = 300;

    let containers = iFrame.contentWindow.document.getElementsByClassName('ad-container');
    let height  = iFrame.contentWindow.document.body.offsetHeight;
    if (containers.length) {
        height = Math.round(containers[0].offsetHeight);
    }

    iFrame.height = height + 1;
}

export default class AdEditorWidget {
  static init() {

      if (window.tinyMCE.get('adeditor')) {
          window.tinyMCE.get('adeditor').remove();
      }

      $(document).on('keyup', '.form-control', () => {
          reloadPreview();
      });

      $('[name^="content"]').on('change', () => {
          reloadPreview();
      });

      $('[name^="target"]').on('change', () => {
          reloadPreview();
      });

      $('[name^="size"]').on('change', () => {
          reloadPreview();
      });

      $('[name="ad_template_id"]').on('change', function () {
          // hide all fields
          $('[name^="content"]').attr('required', false).parent('.form-group').hide();
          $('img[name^="content"]').parents('div').first().hide();


          reloadPreview();

          $.get('/api/v1/company/ad-template/' + $(this).val(), function (response) {

              console.log(response.data.type);
              // show fields for template type
              switch (response.data.type) {
                  case 'geo':
                  case 'text': {
                      $('[name="content[title]"]').attr('required', true).parent('.form-group').show();
                      $('[name="content[description]"]').attr('required', true).parent('.form-group').show();
                      break;
                  }
                  case 'iframe': {
                      $('[name="content[iframe]"]').attr('required', true).parent('.form-group').show();
                      break;
                  }
                  case 'image': {
                      $('[name="content[image]"]').parents('div').first().show();
                      $('[name="content[image]"]').attr('src', response.data.background.url);
                      $('[name="content[image]"]').attr('height', '350px');
                      break;
                  }
                  default: {
                      $('[name="content[html]"]').attr('required', true).parent('.form-group').show();

                      let dom = window.tinyMCE.get('adeditor').dom;
                      let $bar = $('.tox-tinymce');

                      // set dimensions
                      if (response.data.width) {
                          $bar.width(response.data.width);
                      }
                      $bar.height(response.data.height + $('.tox-toolbar').height() + $('.tox-statusbar').height());
                      dom.setStyle(dom.getRoot(), 'overflow', `hidden`);
                      dom.setStyle(dom.getRoot(), 'background-size', `${response.data.width}px ${response.data.height}px`);

                      // set background when exists
                      if (response.data.background) {
                          dom.setStyle(dom.getRoot(), 'background', `url(${response.data.background.url || ''}) no-repeat`);
                      }
                  }
              }
          });
      });

      // init visual editor
      window.tinyMCE.init({
        selector: SELECTOR,
        plugins: [],
        menubar:false,
        toolbar: "styleselect | forecolor | fontsizeselect | fontselect",
        fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt 48pt 56pt 64pt",
        font_formats: 'Roboto=roboto,sans-serif;Helvetica Neue=helvetica neue,sans-serif;Libre Baskerville=libre baskerville,serif;',
        branding: false,
        resize: false,
        scroll: false,
        skin: false,
        content_css: false,
        setup: function (editor) {

          // load and  set initial template
          editor.on('init', function () {

              $('[name="ad_template_id"]').change();

              if (!$(editor.getElement()).html()) {
                  editor.execCommand("fontName", false, "Helvetica Neue");
                  editor.execCommand("fontSize", false, "24pt");
              }
          });

          //persist data on change
          editor.on("change keyup", function(e){
            editor.save();
            $(editor.getElement()).trigger('change');
            $('[name^="target"]').change();
          });
        }
      });

      $('#adPreviewIframe').load(function () {
          resizeIFrameToFitContent(document.getElementById("adPreviewIframe"));
      });
  }
}

