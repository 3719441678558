<template>
    <div>
        <vl-input v-if="withCardholder" :vkompo="chComponent"/>

        <vl-input v-if="withCardholderEmail" :vkompo="chEmailComponent"/>

        <vl-form-field v-bind="$_wrapperAttributes" class="vlMargins">

            <div v-if="withCardSource" class="vlFormControl vlFlex vlAlignCenter">
                <div style="width: 1px">&nbsp;</div>
                <div ref="card" style="flex:1"></div>
            </div>

            <div v-if="withSepaSource" class="vlFormControl vlFlex vlAlignCenter">
                <div ref="iban" style="flex:1"></div>
            </div>

        </vl-form-field>

        <vl-button :vkompo="btnComponent" ref="btn" @click="createSetupIntent" class="float-right"/>
    </div>
</template>

<script>
import Field from 'vue-kompo/js/form/mixins/Field'

export default {
    mixins: [Field],
    data() {
        return {
            stripe: undefined,
            elements: null,
            card: undefined,
            iban: undefined,
            cardholderValue: '',
            chComponent: null,
            cardholderEmailValue: '',
            chEmailComponent: null,
            btnComponent: null,
            loading: false
        }
    },
    computed: {
        cardholderId() {
            return this.$_elementId() + '_name'
        },
        cardholderLabel() {
            return this.$_data('cardholderLabel')
        },
        cardholderName() {
            return 'cardholder-name'
        },
        cardholderError() {
            return this.$_data('cardholderError')
        },
        cardholderEmailId() {
            return this.$_elementId() + 'email'
        },
        cardholderEmailLabel() {
            return this.$_data('cardholderEmailLabel')
        },
        cardholderEmailName() {
            return 'cardholder-email-name'
        },
        cardholderEmailError() {
            return this.$_data('cardholderEmailError')
        },
        withCardholder() {
            return this.chComponent && this.cardholderLabel
        },
        withCardholderEmail() {
            return this.chEmailComponent && this.cardholderEmailLabel
        },
        withSepaSource() {
            return this.$_data('source') === "sepa_debit"
        },
        withCardSource() {
            return this.$_data('source') === "card"
        },

    },
    methods: {
        async createSetupIntent() {
            var payment_method_data = {
                payment_method_data: {
                    billing_details: {}
                }
            }

            if (this.withCardholder) {
                this.setCardholderValue()
                if (!this.cardholderValue) {
                    this.chComponent.$_setError([this.cardholderError])
                    return
                } else {
                    payment_method_data.payment_method_data.billing_details.name = this.cardholderValue
                }
            }

            if (this.withCardholderEmail) {
                this.setCardholderEmailValue()
                if (!this.cardholderEmailValue) {
                    this.chEmailComponent.$_setError([this.cardholderEmailError])
                    return
                } else {
                    payment_method_data.payment_method_data.billing_details.email = this.cardholderEmailValue
                }
            }

            this.$refs.btn.$_state({loading: true});
            let response;

            if (this.withCardSource) {
                response = await this.stripe.handleCardSetup(
                    this.component.$_data('intent'), this.card, payment_method_data
                )
            } else {
                response = await this.stripe.confirmSepaDebitSetup(
                    this.component.$_data('intent'), {
                        payment_method: {
                            sepa_debit: this.iban,
                            billing_details: payment_method_data.payment_method_data.billing_details
                        }
                    }
                )
            }

            if (response.error) {
                this.component.$_setError([response.error.message])
                this.$refs.btn.$_state({loading: false})
            } else {
                this.component.$_setError(null)
                this.component.value = response.setupIntent.payment_method
                this.$_runOwnInteractions('change')
            }
        },
        $_fill(jsonFormData) {
            jsonFormData[this.$_name] = this.$_value || ''
            if (this.withCardholder) {
                jsonFormData[this.cardholderName] = this.cardholderValue
            }
            if (this.withCardholderEmail) {
                jsonFormData[this.cardholderEmailName] = this.cardholderEmailValue
            }
        },
        setCardholderValue() {
            var jsonFormData = {}
            this.chComponent.$_fillRecursive(jsonFormData)
            this.cardholderValue = jsonFormData[this.cardholderName]
        },
        setCardholderEmailValue() {
            var jsonFormData = {}
            this.chEmailComponent.$_fillRecursive(jsonFormData)
            this.cardholderEmailValue = jsonFormData[this.cardholderEmailName]
        }
    },
    mounted() {
        let stripeScript = document.createElement('script')
        stripeScript.onload = () => {

            this.stripe = typeof Stripe !== 'undefined' ? Stripe(process.env.MIX_STRIPE_KEY, {locale: 'de'}) : undefined

            this.elements = this.stripe.elements({
                fonts: this.$_data('fontSrc') ? [{cssSrc: this.$_data('fontSrc')}] : []
            })

            if (this.withCardSource) {
                this.card = this.elements.create('card', {style: {base: this.$_data('styles')}})
                this.card.mount(this.$refs.card)
            }

            if (this.withSepaSource) {
                this.iban = this.elements.create('iban', {style: {base: this.$_data('styles')},
                    supportedCountries: ['SEPA'],})
                this.iban.mount(this.$refs.iban)
            }

        }
        stripeScript.async = true
        stripeScript.setAttribute('src', 'https://js.stripe.com/v3/')
        document.head.appendChild(stripeScript)
    },
    created() {

        this.btnComponent = Object.assign({}, this.vkompo, {
            data: {
                loading: true,
            }
        })

        this.chComponent = Object.assign({}, this.vkompo, {
            placeholder: this.cardholderLabel,
            id: this.cardholderId,
            data: {
                noLabel: true,
            },
            name: this.cardholderName,
            interactions: [],
        })

        this.chEmailComponent = Object.assign({}, this.vkompo, {
            placeholder: this.cardholderEmailLabel,
            id: this.cardholderEmailId,
            data: {
                noLabel: true,
            },
            name: this.cardholderEmailName,
            interactions: [],
        })
    }
}
</script>

