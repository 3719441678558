<template>
    <div>
        <vl-form-field v-bind="$_wrapperAttributes">
            <div class="flex-row">
                <vlTaggableInput
                    v-bind="$_taggableInputAttributes"
                    v-on="$_taggableInputEvents"
                    :labelKey="labelKey">
                    <input
                        @click="addFile"
                        v-bind="$_attributes"
                        v-on="$_events"
                        class="vlFormControl"
                        type="text"
                        ref="input"/>
                    <template v-slot:append>
                        <i class="fa fa-folder"/>
                    </template>
                </vlTaggableInput>
                <a class="btn btn success d-block" @click="loadUploader">
                    Hochladen <i class="fa fa-upload"/>
                </a>
            </div>
            <vl-modal :name="'modal'+$_elKompoId" @closed="loadGallery">
                <file-manager v-bind:settings="settings" ref="fm"></file-manager>
            </vl-modal>
        </vl-form-field>

        <vl-album-thumbnail-gallery
            class="vlAlbumGallery"
            @sort="sortGallery"
            @edit="edit"
            :images="thumbnails ? thumbnails : []"
            :height="thumbHeight"
            :previewable="previewable"/>
    </div>
</template>

<script>
import FieldFile from 'vue-kompo/js/form/mixins/FieldFile'
import HasTaggableInput from 'vue-kompo/js/form/mixins/HasTaggableInput'

export default {
    mixins: [FieldFile, HasTaggableInput],
    data(){
        return {
            files: [],
            thumbnails: [],
            thumbHeight: "150px",
            previewable: true,
            labelKey: 'basename',
            folder: {},
            settings: {
                baseUrl: '/media/',
                windowsConfig: 1,
                allowedTypes: ['dir'],
                path: '',
                file: '',
            },
        }
    },
    mounted() {
        this.loadGallery();
    },
    methods: {
        $_emptyInput() {
            this.inputValue = ''
            this.thumbnails = [];
        },
        $_click(selection) {
            if (this.$_readOnly)
                return
            // remove focus trigger to prevent removal upon modal show
            var input = this.$refs.input.$el || this.$refs.input
            input.click()
        },
        addFile(){
            this.$kompo.vlModalShow('modal' + this.$_elKompoId)
            this.settings.path = this.$_value[0] ? this.$_value[0].path : '';
            this.$store.commit('fm/setFileCallBack', (folder) => {
                if (folder) {
                    this.$_addFileToValue(folder)
                    this.$_blurActionDelayed()
                    this.$_changeAction()
                }
                this.$kompo.vlModalClose('modal' + this.$_elKompoId)
                this.loadGallery()
            });
        },
        loadUploader(index) {
            this.addFile();
            this.$store.commit('fm/modal/setModalState', {
                modalName: 'Upload',
                show: true,
            });
        },
        edit(index) {
            this.addFile();

            this.$store.commit('fm/left/setDirectoryContent', {
                directories: [],
                files: this.files,
                result: {
                    message: null,
                    status: 'success',
                }
            });

            this.$store.commit('fm/left/changeSelected', {
                path: this.files[index].path,
                type: "files",
            });

            this.$store.commit('fm/modal/setModalState', {
                modalName: 'Rename',
                show: true,
            });
        },
        loadGallery() {
            if (!this.$_value[0]) {
                return;
            }
            axios.get(`/media/content?disk=s3&company_id=${environment.company_id}&path=` + (this.$_value[0] ? this.$_value[0].path : '')).then((response) => {
                this.files = response.data.files;
               this.thumbnails = response.data.files.map((item) => {
                   return {
                       id: item.path,
                       src: item.download_url,
                       name: item.basename,
                   }
               })
            });
        },
        sortGallery(event) {
            axios.patch('/media/sort', {
                path: (this.$_value[0] ? this.$_value[0].path : ''),
                oldIndex: event.moved.element.oldIndex,
                newIndex: event.moved.element.newIndex,
            });
        },
        $_fill(jsonFormData) {
            if (this.$_value.length) {
                jsonFormData[this.$_name] = this.$_value[0].path
            } else {
                jsonFormData[this.$_name] = null
            }
        },
    },
}
</script>
