import AdLocation from './adLocation';
import TabWidget from "../../../base/js/widget/tab";

require('./location');

let calculatePriceTimeout = null;
let previousFormDataQuery = '';

function autoSave(formData) {
    if (window.location.href.indexOf('state=create') !== -1) {
        $.ajax({
            url: $('#adForm').attr('action'),
            method: 'post',
            data: formData,
        })
    }
}

function calculatePrice()
{
    if (!$('#adForm').length) {
        return
    }

    $('.form-control, input').on('invalid', function (e) {
        let tabPane = $(e.target).parents('.tab-pane');
        let id = tabPane.attr('id');
        if (!tabPane.hasClass('active')) {
            TabWidget.showTab(id);
            setTimeout(function () {
                $(e.target).parents('form').find('button[type="submit"]').click();
            }, 1000);
        }
    });

    $('#average_price_container').btnProgress('show');
    clearTimeout(calculatePriceTimeout);
    calculatePriceTimeout = setTimeout(() => {
        let formData = $('#adForm').serializeForm();
        formData['company_id'] = environment.company_id;
        formData['average_price'] = 0;
        let formDataQuery = $.param(formData);

        if (previousFormDataQuery === formDataQuery && $('#average_price_container').text() !== "-") {
            return;
        }

        if (previousFormDataQuery) {
           autoSave(formData)
        }

        previousFormDataQuery = formDataQuery;

        $.ajax({
            url: `/api/v1/ad/estimate`,
            method: 'post',
            data: formData,
            success: function (data) {

                if (data.data.status_id && data.data.status_id > 299) {
                    $('#toast-container').remove();
                    $.admin.toastr.error(`<i class="fas fa-times-circle"/> ${data.data.status_message} <br/> ${data.data.id}`, '', {
                        positionClass: "toast-top-center",
                        timeOut: 10000
                    });
                } else if (data.data.status_id && data.data.status_id > 200) {
                    $('#toast-container').remove();
                    $.admin.toastr.warning(`<i class="fas fa-times-circle"/> ${data.data.status_message} <br/> ${data.data.id}`, '', {
                        positionClass: "toast-top-center",
                        timeOut: 10000
                    });
                }

                var price = data.data.average_price;
                $('#average_price_container').find('span').html(
                    String(Number.parseFloat(price).toFixed(2)).replace('.', ',')
                    + ' €'
                );

                $('#average_price_container').btnProgress('hide');
                $('[name="average_price"]').val(price);
                $('.js-adinhabitant').html(data.data.inhabitants);
                $('.js-adinhabitant-id').html(data.data.id);
                $('.js-adinhabitant-interest').html(data.data.total_inhabitants_including_interests);
                $('input[name="average_price_id"]').remove();
            },
            error: function (data) {
                $('#average_price_container').btnProgress('hide');
                $('#average_price_container').find('span').html('N/A');
                $('[name="average_price"]').val('N/A')
                $('.js-adinhabitant').html('N/A');
                $('.js-adinhabitant-interest').html('N/A');
            },
        });
    }, 1000);
}

$(function () {
    setTimeout(function () {
        $(document).on('change', 'input', (event) => {
            calculatePrice();
        });
        $(document).on('blur', '.form-control', (event) => {
            calculatePrice();
        });
        $(document).on('change', 'select', (event) => {
            calculatePrice();
        });
        $(document).on('click', '.switch-input', (event) => {
            calculatePrice();
        });
    }, 100);
});

$(document).on('pjax:success', function() {
    if ($('#adLocationMap').length) {
        new AdLocation('adLocationMap', $('#geo_rad').data('locations'));
    }

    /**
     * Check whenever page initialization is needed
     */
    if (!$('#adForm').length) {
        clearTimeout(calculatePriceTimeout);
        return false;
    }

    let adLocation = new AdLocation('googlemap', $('#geo_rad').data('locations'), {
        onChange: (position, positions) => {
            $('input[name^="location"]').remove();

            positions.forEach((position, index) => {
                $('<input/>')
                    .attr('name', 'location['+index+'][lat]')
                    .attr('value', position.location.lat())
                    .attr('type', 'hidden')
                    .appendTo($('#adForm'));

                $('<input/>')
                    .attr('name', 'location['+index+'][lon]')
                    .attr('value', position.location.lng())
                    .attr('type', 'hidden')
                    .appendTo($('#adForm'));

                $('<input/>')
                    .attr('name', 'location['+index+'][radius]')
                    .attr('value', position.circle.radius)
                    .attr('type', 'hidden')
                    .appendTo($('#adForm'));

                if ($('input[name="street"]').val().length) {
                    $('<input/>')
                        .attr('name', 'location[' + index + '][street]')
                        .attr('value', $('input[name="street"]').val())
                        .attr('type', 'hidden')
                        .appendTo($('#adForm'));
                }

                if ($('input[name="street_no"]').val().length) {
                    $('<input/>')
                        .attr('name', 'location[' + index + '][street_no]')
                        .attr('value', $('input[name="street_no"]').val())
                        .attr('type', 'hidden')
                        .appendTo($('#adForm'));
                }

                if ($('input[name="zip_code"]').val().length) {
                    $('<input/>')
                        .attr('name', 'location[' + index + '][zip_code]')
                        .attr('value', $('input[name="zip_code"]').val())
                        .attr('type', 'hidden')
                        .appendTo($('#adForm'));
                }

                if ($('input[name="city"]').val().length) {
                    $('<input/>')
                        .attr('name', 'location[' + index + '][city]')
                        .attr('value', $('input[name="city"]').val())
                        .attr('type', 'hidden')
                        .appendTo($('#adForm'));
                }
            });
        },
        onSelect: (position) => {

            $('input[name="geo_search[street]"]').val('');
            $('input[name="geo_search[zip_code]"]').val('');
            $('input[name="geo_search[city]"]').val('');
            $('input[name="geo_search[street_no]"]').val('');
            $('#geo_rad').val(position.circle.radius);

            $.ajax({
                url: 'https://api.geo.inquence.com/api/v1/reverse-geocode',
                method: 'GET',
                data: {
                    lat: position.location.lat(),
                    lon: position.location.lng(),
                },
            }).done((response) => {
                if (response.status === 'ok' && response.data) {
                    $('input[name="geo_search[street]"]').val(response.data.street || '');
                    $('input[name="geo_search[zip_code]"]').val(response.data.postcode || '');
                    $('input[name="geo_search[city]"]').val(response.data.location_name || '');
                }
            });
        },
        onError: (error) => {
            $.admin.toastr.error('<i class="fas fa-times-circle"></i> ' + error, '', {
                positionClass: "toast-top-center",
                timeOut: 10000
            });
        }
    });

    /**
     * Update duration to date on duration from change
     */
    $('[name="duration_from"]').on('change', (event) => {
        let $target = $(event.target);
        let dateFragments = $target.val().split('.');
        let durationToDate = new Date(dateFragments[2], dateFragments[1], dateFragments[0]);

        durationToDate.setMonth(durationToDate.getMonth() + 12);

        let month = durationToDate.getMonth();
        if (month < 10)  {
            month = '0' + month;
        }

        let date = durationToDate.getDate();
        if (date < 10) {
            date = '0' + date
        }

        $('[name="duration_to"]').val(`${date}.${month}.${durationToDate.getFullYear()}`);
    });

    /**
     * Init default average price
     */
    setTimeout(() => {
        if ($('#average_price_container').text() === "-") {
            calculatePrice();
        }
    }, 100);

    $('.js-ad-location-action-remove').on('click', function (e) {
        e.preventDefault();
        adLocation.removeCurrentLocation();
    });

    /**
     * Init location search
     */
    $('.js-ad-location-action').on('click', function (e) {
        let values = $('input[name^="street"], input[name^="street_no"], input[name^="zip_code"], input[name^="city"]').serializeArray();
        e.preventDefault();

        if ($(e.target).val() == "update") {
            adLocation.updateCurrentLocation({
                radius: Number.parseFloat($('input[name="geo_rad"]').val()),
            });
        }

        if (!values[0].value && !values[1].value && !values[2].value && !values[3].value) {
            return;
        }

        $.ajax({
            url: 'https://api.geo.inquence.com/api/v1/parse-address-components',
            data: {
                street: values[0].value || '1',
                street_no: values[1].value || '1',
                zipcode: values[2].value || '1',
                city: values[3].value || 'Dresden',
                country: 'DE',
            },
            beforeSend: () => {
                $(e.target).btnProgress('show');
            },
        }).done((response) => {
            if (response.data && response.data.hits.length) {
                let hit = response.data.hits[0];
                let location = {
                    lat: hit._geoloc.lat,
                    lon: hit._geoloc.lng,
                    radius: Number.parseFloat($('input[name="geo_rad"]').val()),
                };

                switch ($(e.target).val()) {
                    case "update": {
                        adLocation.updateCurrentLocation(location);
                        break;
                    }
                    case "new": {
                        adLocation.drawLocation(location);
                    }
                }

                calculatePrice();
            }
        }).error(() => {
            $.admin.toastr.error('<i class="fas fa-times-circle"></i>', '', {
                positionClass: "toast-top-center",
                timeOut: 10000
            });
        }).always(() => {
            $(e.target).btnProgress('hide');
        });
    })
});
