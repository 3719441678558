const SELECTOR = '[data-widget="chart"]';

export default class ChartJs {
  static init() {
    document.querySelectorAll(SELECTOR).forEach(function (el) {
        let ctx = el.getContext('2d');
        new Chart(ctx, JSON.parse(el.dataset.config))
    });
  }
}
