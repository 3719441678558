import "bootstrap-fileinput/themes/explorer-fas/theme.css";

const SELECTOR = '[data-widget="bootstrap-singlefileinput"]';

export default class FileInputWidget {

  static init() {
      require("bootstrap-fileinput/js/locales/de.js");

    $(SELECTOR).fileinput({
      theme: "fas",
      initialPreviewAsData:true,
      language: document.documentElement.lang,
      showUpload: false,
      showCancel: false,
      msgZoomModalHeading: '',
      fileActionSettings: {
        showRemove: false,
        showDrag: false,
        showUpload: false,
      },
      initialPreviewShowDelete: false,
    }).on('filecleared', function (event) {
      let $destroy = $('<input/>').attr('type', 'hidden').attr('name', `_${$(event.target).attr('name')}_destroy`).val(1);
      $(event.target).parents('form').append($destroy);
    }).on('fileerror', function(event, data, msg) {
        $(event.target).parents('form').find('button[type="submit"]').attr('disabled', true)
    }).on('filecleared', function(event, key, jqXHR, data) {
        $(event.target).parents('form').find('button[type="submit"]').attr('disabled', false)
    });
  }
}
