import {TimePickerWidget} from '@base/widget'
import DatePickerWidget from "../../../base/js/widget/datepicker";

$(function () {
    /**
     * Initialize basic logic for open hours form
     */
    $(document).off('click', '.js-hourForm__new').on('click', '.js-hourForm__new', (e) => {
        let name = $(e.target).attr('name').replace('new', 0);
        let baseName = $(e.target).attr('name').replace('[new]', '');
        let $hours = $(`[name^="${name}"]`).parents('.form-group').clone();
        let index = Math.round($(`input[name^="${baseName}"]`).length / 2);

        $($hours[0]).find('input').attr('name', `${baseName}[${index}][open_to]`).val('');
        $($hours[1]).find('input').attr('name', `${baseName}[${index}][open_from]`).val('');

        $($hours[1]).insertBefore($(e.target));
        $($hours[0]).insertBefore($(e.target));

        TimePickerWidget.init();
    });

    /**
     * Unshift hours segment(from, to) from chosen weekday
     */
    $(document).on('click', '.js-hourForm__destroy', e => {
        let baseName = $(e.target).attr('name').replace('[remove]', '');
        let index = Math.round($(`input[name^="${baseName}"]`).length / 2) - 1;

        if (index > 0) {
            $(`[name^="${baseName}[${index}]"]`).remove();
        }
    });

    /**
     * Append new hours segment(from, to) to chosen weekday
     */
    $('.js-hourForm__newday').on('click', (e) => {
        let name = $(e.target).attr('name').replace('new', 0);
        let baseName = $(e.target).attr('name').replace('[new]', '');
        let $hours = $(`[name^="${name}"]`).parents('.form-group').clone();
        let index = Math.round($(`input[name^="${baseName}"]`).length / 2);

        $($hours[0]).find('input').attr('name', `${baseName}[${index}][open_to]`).val('');
        $($hours[1]).find('input').attr('name', `${baseName}[${index}][open_from]`).val('');

        $($hours[1]).insertBefore($(e.target));
        $($hours[0]).insertBefore($(e.target));

        TimePickerWidget.init();
    });

    /**
     * Delete hours group
     */
    $(document).on('click', '.js-hourForm__deletegroup', (e) => {
        e.preventDefault();
        $(e.target).parents('.js-hourForm__group').remove();
    });

    /**
     * Handle coping of monday hours to other weekdays
     */
    $(document).on('click', '.js-hourForm__copyMonday', (e) => {
        let target = $(e.target).data('target');

        let numberOfHoursInMonday = ($(`[name^="${target}[2]"]`).length - 2) / 2 - 1;

        $(e.target).parents('.js-hourForm__group').find('.switch-input:checked').click();
        $(e.target).parents('.js-hourForm__group').find('.switch-input').click();

        for (let i = 0; i < numberOfHoursInMonday; i++) {
            let $destroyButtons = $(e.target).parents('.js-hourForm__group').find('.js-hourForm__destroy');
            $destroyButtons.not($destroyButtons.first()).click();
        }

        for (let i = 0; i < numberOfHoursInMonday; i++) {
            let $newButtons = $(e.target).parents('.js-hourForm__group').find('.js-hourForm__new');
            $newButtons.not($newButtons.first()).click();
        }

        for (let i = 1; i <= 7; i++) {
            for (let j = 0; j < numberOfHoursInMonday+1; j++) {
                let mondayFrom = $(`[name^="${target}[2][${j}][open_from]"]`).val();
                let mondayTo = $(`[name^="${target}[2][${j}][open_to]"]`).val();

                $(`[name^="${target}"][name$="${target}[${i}][${j}][open_from]"]`).val(mondayFrom);
                $(`[name^="${target}"][name$="${target}[${i}][${j}][open_to]"]`).val(mondayTo);
            }
        }

        e.preventDefault();
    });

    /**
     * Create new hour group
     */
    $(document).on('click', '.js-hourForm__newgroup', (e) => {
        e.preventDefault();
        $('.js-hourForm__container').append(
          $('.js-hourForm__template').html().split("day[default]").join("day[" + ($('.js-hourForm__group').length + 1) + "]")
        );
        TimePickerWidget.init();
        DatePickerWidget.init();
    });
});
