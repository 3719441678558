import Cookies from 'cookies-js';

export default class GeoLocation {
    static init() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                GeoLocation.handlePosition,
                GeoLocation.handleError
            );
        } else {
            GeoLocation.handleError({
                code: 'not supported'
            })
        }
    }

    static handlePosition(position) {
        let location = {};

        if (position.lat && position.lng) {
            location = {
                lat: position.lat,
                lng: position.lng,
            }
        } else {
            location = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            }
        }

        location = btoa(JSON.stringify(location));

        // watch for changes and show popup when access grant revoked
        // let inid = setInterval(function () {
        //     navigator.permissions.query({name: 'geolocation'}).then(function (result) {
        //         if (result.state != 'granted') {
        //             GeoLocation.init();
        //             clearInterval(inid);
        //         }
        //     });
        // }, 1000);

        $(function () {
            $(document).on('click', '.js-reset-location', function (event) {
                event.preventDefault();
                Cookies.set('regiowerbung_geo', location, {expires: Infinity});
                $.admin.toastr.success('<i class="fas fa-compass"></i>', '', {
                    positionClass: "toast-top-center",
                    timeOut: 5000
                });
                $.admin.reload();
            });
        });

        if (localStorage.setItem) {
            localStorage.setItem('p', atob(location))
        }

        if (!Cookies.get('regiowerbung_geo')) {
            Cookies.set('regiowerbung_geo', location, {expires: Infinity});
        }

        // handle coordinates there
    }

    static handleError(error) {
        switch (error.code) {
            case error.POSITION_UNAVAILABLE:
                break;
            case error.TIMEOUT:
                break;
            default:
                if (localStorage.getItem && localStorage.getItem('p')) {
                    let position = JSON.parse(localStorage.getItem('p'));
                    if (position) {
                        GeoLocation.handlePosition(position);
                        break;
                    }
                }

                if (!environment) {
                    return;
                }

                $.ajax({
                    url: 'https://api.geo.inquence.com/api/v1/location',
                    method: 'GET',
                    data: {
                        ip: environment.ip,
                    },
                }).done((response) => {
                    if (response.status === 'ok' && response.data.lat && response.data.lon) {
                        let position = {
                            coords: {
                                latitude: response.data.lat,
                                longitude: response.data.lon
                            }
                        };

                        if (localStorage.setItem) {
                            localStorage.setItem('p', JSON.stringify(position))
                        }

                        GeoLocation.handlePosition(position)
                    }
                });
                // $('#geolocation-overlay').modal('toggle');
                // let inid = setInterval(function () {
                //     navigator.permissions.query({name:'geolocation'}).then(function(result) {
                //         if (result.state == 'granted') {
                //             $('#geolocation-overlay').modal('toggle');
                //             GeoLocation.init();
                //             clearInterval(inid);
                //         }
                //     });
                // }, 100);
                break;
        }
    }
}
