import AdLocation from './adLocation';

$(document).on('pjax:success', function() {

    if (!$('#adLocationForm').length) {
        return false;
    }

    $('button[type="submit"]').on('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        $('.js-ad-location-action').click();
        setTimeout( function () {
            $('#adLocationForm').submit();
        }, 500)
    })

    let adLocation = new AdLocation('googlemap', [
        {
            lat: $('[name="lat"]').val(),
            lon: $('[name="lon"]').val(),
            radius: Number.parseInt($('[name="radius"]').val()),
        }
    ], {
        onChange: (position, positions) => {
            positions.forEach((position, index) => {
                $('[name="lat"]').val(position.location.lat());
                $('[name="lon"]').val(position.location.lng());
                $('[name="radius"]').val(position.circle.radius);
            });
        },
    });

    /**
     * Init location search
     */
    $('.js-ad-location-action').on('click', function (e) {
        let values = $('input[name^="street"], input[name^="street_no"], input[name^="zip_code"], input[name^="city"]').serializeArray();
        e.preventDefault();

        if (!values[0].value && !values[1].value && !values[2].value && !values[3].value) {
            return;
        }

        $.ajax({
            url: 'https://api.geo.inquence.com/api/v1/parse-address-components',
            data: {
                street: values[0].value || '1',
                street_no: values[1].value || '1',
                zipcode: values[2].value || '1',
                city: values[3].value || 'Dresden',
                country: 'DE',
            },
            beforeSend: () => {
                $(e.target).btnProgress('show');
            },
        }).done((response) => {
            if (response.data && response.data.hits.length) {
                let hit = response.data.hits[0];
                let location = {
                    lat: hit._geoloc.lat,
                    lon: hit._geoloc.lng,
                    radius: Number.parseFloat($('input[name="geo_rad"]').val()),
                };

                adLocation.updateCurrentLocation(location);
            }
        }).error(() => {
            $.admin.toastr.error('<i class="fas fa-times-circle"></i>', '', {
                positionClass: "toast-top-center",
                timeOut: 10000
            });
        }).always(() => {
            $(e.target).btnProgress('hide');
        });
    })
});
