<template>
    <div>
        <div class="gallery-list clearfix large-gallery">
            <span>
            <draggable
                @change="sort"
                v-if="images.length > 0"
                :list="images"
                class="gallery-list clearfix"
                :class="images.length > 4 ? 'large-gallery' : '' ">
                <transition-group name="gallery-transition">
                    <vl-album-thumbnail
                        v-for="(image, index) in images"
                        :key="index + 0"
                        :image="image"
                        :height="calculatedHeight"
                        :index="index"
                        :previewable="previewable"
                        @remove="remove"
                        @edit="edit"
                        @preview="previewAndOpen"/>
                    </transition-group>
                </draggable>
            </span>
        </div>

        <vl-thumbnail-modal
            v-if="previewable"
            :name="modalname"
            :image="previewImage"
            :length="images.length"
            @next="nextImage"
            @previous="previousImage"/>

    </div>
</template>

<script>

import draggable from 'vuedraggable'

export default {
    components: {draggable},

    props: {
        images: {type: Array, required: true},
        height: {type: String, required: false},
        previewable: {type: Boolean, default: true}
    },

    data: () => ({
        previewIndex: null,
        previewImage: null,
        modalname: null
    }),

    computed: {
        calculatedHeight() {
            return this.height ? this.height : (this.images.length > 4 ? '2.9rem' : '6.3rem')
        }
    },

    methods: {
        sort(data) {
           this.$emit('sort', data);
        },
        previewAndOpen(index) {
            this.preview(index)
            this.$kompo.vlModalShow(this.modalname)
        },
        preview(index) {
            this.previewIndex = index
            this.previewImage = this.images[this.previewIndex]
        },
        previousImage() {
            this.preview(this.previewIndex == 0 ? this.images.length - 1 : this.previewIndex - 1)
        },
        nextImage() {
            this.preview(this.previewIndex == this.images.length - 1 ? 0 : this.previewIndex + 1)
        },
        remove(index) {
            this.$emit('remove', index)
        },
        edit(index) {
            this.$emit('edit', index)
        }
    },
    mounted() {
        this.modalname = 'image-preview' + this._uid
    }
}
</script>
