export default class FormWizardWidget {
  static init() {
      require('twitter-bootstrap-wizard');
      $('#rootwizard').bootstrapWizard({
          'tabClass': 'wizardroot',
          nextSelector: '.wizard .next',
          previousSelector: '.wizard .previous',
          finishSelector: '.wizard .finish',
          onNext: function (tab, navigation, index) {
              let $form = $(tab).parents('form');
              if (!$form[0].checkValidity()) {
                  $form.find('button[type="submit"]').click();
                  return false;
              }
          },
          onTabShow: function (tab) {
              $('.nav-link.active').toggleClass('active');
              tab.find('.nav-link').toggleClass('active');
              $('.nav-link.active').click();
          }
      });
  }
}

