<template>
    <div class="row">
        <div class="col-12 col-md-4 col-lg-3">
            <div class="list-group list-group-accent" id="list-tab" role="tab" v-for="(tab, i) in tabs">
                <a
                   :key="i"
                   v-html="tab.header"
                   :aria-selected="tab.isActive"
                   @click.prevent="selectTab(i)"
                   role="tab"
                   :class="!tab.isActive ? 'list-group-item list-group-item-action' : 'list-group-item list-group-item-action active'"
                   id="list-profile-list"
                   data-toggle="tab"
                   :href="i+'tab'"
                   aria-controls="profile"
                ></a>
            </div>
        </div>
        <div class="col-12 col-md-8 col-lg-9">
            <div class="tab-content" style="border: none; border-top: inherit;">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        activeTab: {type: Number, default: 0}
    },
    watch: {
        activeTab: function (newVal) {
            this.selectTab(newVal)
        }
    },
    data: () => ({
        tabs: []
    }),
    created() {
        this.tabs = this.$children
    },
    mounted() {
        if (this.tabs.length)
            this.selectTab(0)
    },
    methods: {
        selectTab(index) {
            const selectedTab = this.tabs[index] || this.tabs[this.activeTab]
            if (!selectedTab || selectedTab.isDisabled)
                return

            this.$emit('tabChange', index)

            this.tabs.forEach((tab) => {
                tab.isActive = (selectedTab == tab)
            })

            this.$kompo.events.$emit('vlTabChange', index) //mainly for select resizing
            this.$emit('vlTabChange', index)
        }
    },
};
</script>
