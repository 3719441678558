<template>
    <div v-bind="$_layoutWrapperAttributes" v-show="!$_hidden">

        <component :hidden="showEmptyState()" v-bind="$_attributes(komponents[0])" :key="renderKey">
            <template v-slot:after>
                <a class="vlFormComment"
                    href="javascript:void(0)"
                    @click.stop="loadUpdateForm"
                    :class="$_data('addLabelClass')"
                    v-html="$_data('addLabel')" />
            </template>
        </component>

        <div v-if="showEmptyState()" class="vlFlexCol vlFlexCenter">
            <p>
                {{ $_data('addMessage') }}
            </p>
            <a class="vlBtn vlBtnOutlined"
               href="javascript:void(0)"
               @click.stop="loadUpdateForm"
               :class="$_data('addLabelClass')"
               v-html="$_data('addLabel')" />
        </div>

        <vl-modal :name="'modal'+$_elKompoId">
            <vl-form :vkompo="updateForm" @success="updateOptionsAndValue"/>
        </vl-modal>

    </div>
</template>

<script>

import Layout from 'vue-kompo/js/form/mixins/Layout'
import DoesAxiosRequests from 'vue-kompo/js/form/mixins/DoesAxiosRequests'

export default {
    mixins: [Layout, DoesAxiosRequests],
    data(){
        return {
            updateForm: false,
            renderKey: 0,
            optionValue: null,
            option: null
        }
    },
    methods: {
        showEmptyState() {
            return !this.komponents[0].options.length && this.$_data('addMessage')
        },
        loadUpdateForm(){

            this.$_kAxios.$_loadKomposer().then(r => {

                this.updateForm = r.data
                this.$kompo.vlModalShow('modal'+this.$_elKompoId)

            })

        },
        updateOptionsAndValue(r){
            this.option = r.data.option //The user has to set a public option property on the Form
            this.optionValue = Object.keys(this.option)[0]

            // Bypass data on consecutive calls to updatable modal form
            this.$_data({
                ajaxPayload: Object.assign({id: this.optionValue}, this.$_data('ajaxPayload')),
            })

            var newSelect = this.komponents[0]

            var index = _.findIndex(newSelect.options, {value: this.optionValue});

            if (index !== -1) {
                newSelect.options.splice(index, 1, {
                    value: this.optionValue,
                    label: this.option[this.optionValue]
                })
            } else {
                newSelect.options.push({
                    value: this.optionValue,
                    label: this.option[this.optionValue]
                })
            }

            newSelect.value = this.optionValue

            this.komponents.splice(0, 1, newSelect)
            this.renderKey += 1

            if(!this.$_data('keepModalOpen'))
                this.$kompo.vlModalClose('modal'+this.$_elKompoId)
        }
    }
}
</script>
