<template>
    <vl-form-field v-bind="$_wrapperAttributes">
        <textarea
            class="form-control"
            v-bind="$_attributes"
            v-on="$_events"
            :rows="$_data('rows')"
            :id="this.$_elKompoId+'editor'">
        </textarea>
    </vl-form-field>
</template>

<script>
import Field from 'vue-kompo/js/form/mixins/Field'
import TinyMCEditor from './mixins/TinyMCEditor'

export default {
    mixins: [Field, TinyMCEditor],
}
</script>

