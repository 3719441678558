const SELECTOR = 'input[data-widget="timepicker"]';
const SELECTOR_INPUT = 'input[type="time"]';

export default class TimePickerWidget {
  static init() {
        $(SELECTOR).datetimepicker({
            format: 'HH:mm',
        });

      $(SELECTOR_INPUT).datetimepicker({
          format: 'HH:mm',
      });
  }
}
