<template>
    <div class="modal-content fm-modal-preview">
        <div class="modal-header">
            <h5 class="modal-title w-75 text-truncate">
                {{ lang.modal.preview.title }}
                <small class="text-muted pl-3">{{ selectedItem.basename }}</small>
            </h5>
            <a type="button" class="close" aria-label="Close" v-on:click="hideModal">
                <span aria-hidden="true">&times;</span>
            </a>
        </div>
        <div class="modal-body text-center">
            <transition name="fade" mode="out-in">
                <iframe :src='documentSrc' height="350px" width="100%" frameborder="0">
                </iframe>
            </transition>
        </div>
        <div v-if="showFooter" class="d-flex justify-content-between">
            <span class="d-block">
                <a class="btn btn-light" v-on:click="hideModal">{{ lang.btn.cancel }}</a>
            </span>
        </div>
    </div>
</template>

<script>
import modal from '../mixins/modal';
import translate from '../../../mixins/translate';
import helper from '../../../mixins/helper';

export default {
  name: 'PreviewDocument',
  mixins: [modal, translate, helper],
  data() {
    return {
      documentSrc: '',
    };
  },

  created() {
    this.loadDocument();
  },
  computed: {
    /**
     * Authorization required
     * @return {*}
     */
    auth() {
      return this.$store.getters['fm/settings/authHeader'];
    },

    /**
     * Selected disk
     * @returns {*}
     */
    selectedDisk() {
      return this.$store.getters['fm/selectedDisk'];
    },

    /**
     * Selected file
     * @returns {*}
     */
    selectedItem() {
      return this.$store.getters['fm/selectedItems'][0];
    },

    /**
     * Show modal footer
     * @return boolean
     */
    showFooter() {
      return true;
    },

    /**
     * Calculate the max height for image
     * @returns {number}
     */
    maxHeight() {
      if (this.$store.state.fm.modal.modalBlockHeight) {
        return this.$store.state.fm.modal.modalBlockHeight - 170;
      }

      return 300;
    },
  },
  methods: {
    /**
     * Load image
     */
    loadDocument() {
        this.documentSrc = 'https://docs.google.com/viewer?url=' + encodeURIComponent(this.selectedItem.download_url) + '&embedded=true';
    },
  },
};
</script>

<style lang="scss">
    .fm-modal-preview {

        .modal-body {
            padding: 0;

            img {
                max-width: 100%;
            }
        }

        & > .d-flex {
            padding: 1rem;
            border-top: 1px solid #e9ecef;
        }
    }
</style>
