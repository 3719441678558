const SELECTOR = '[data-widget="todo"]';

export default class TodoWidget {
  static init() {

      let $widget = $(SELECTOR);

      if (!$widget.length) {
          return;
      }

      // Data Model
      var todos = [];
      var todoKey = 'todo' + environment.user_id;
      if (localStorage.getItem(todoKey)) {
          todos = JSON.parse(localStorage.getItem(todoKey));
      }

      // Application
      var template;
      var app = {
          init: function () {
              app.compileTemplates();
              app.render();
          },
          render: function () {
              // render the todos
              var todoHtml = todos.map(function (todo) {
                  return template
                      .replace('%title%', todo.title)
                      .replace('%checked%', todo.completed ? 'checked' : '')
                      .replace('%style%', todo.completed ? 'text-decoration: line-through' : '')
              });
              app.unbindEvents();
              $widget.find('ul.list-group').html(todoHtml.join(""));
              app.bindEvents();
              app.persist();
          },
          compileTemplates: function () {
              template = $widget.find('.x-template');
              template = template.first().html();
          },
          unbindEvents: function () {
              $widget.find('.list-group-item').off();
              $widget.find('.add-todo-container button').off();
              $widget.find('input[type="checkbox"]').off();
              $widget.find('.list-group-item button').off();
          },
          bindEvents: function () {
              app.bindHoverEvents();
              app.bindCheckboxEvents();
              app.bindAddTodoEvents();
              app.bindRemoveTodoEvents();
          },
          bindHoverEvents: function () {
              var $items = $widget.find('.list-group-item');
              $items.on('mouseover', function (event) {
                  $(this).addClass('list-group-item-success');
              });
              $items.on('mouseout', function () {
                  $(this).removeClass('list-group-item-success');
              });
          },
          bindCheckboxEvents: function () {
              var $checkboxes = $widget.find('input[type="checkbox"]');
              $checkboxes.on('change', function () {
                  var wasChecked = $(this).is(':checked');

                  if (!wasChecked) {
                      $(this).parent().removeClass("disabled");
                  } else {
                      $(this).parent().addClass("disabled");
                  }

                  var index = $(this).parent().index();
                  todos[index].completed = wasChecked;
                  app.render();
              });
          },
          bindAddTodoEvents: function () {
              var $container = $widget.find('.add-todo-container');
              $container.find('button').on('click', function () {
                  var newTodoTitle = $container.find('input').val();
                  if (typeof newTodoTitle === 'string' && newTodoTitle.length > 2) {
                      var newTodoObject = {title: newTodoTitle, completed: false};
                      todos.push(newTodoObject);
                      $container.find('input').val("");
                      app.render();
                  }
              });
          },
          bindRemoveTodoEvents: function () {
              $widget.find('.list-group-item button').on('click', function () {
                  var index = $(this).parent().index();
                  todos.splice(index, 1);
                  app.render();
              });
          },
          persist: function () {
              localStorage.setItem(todoKey, JSON.stringify(todos));
          }
      };


      app.init();
  }
}

