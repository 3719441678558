export default class AddressSuggestWidget {
  static init() {
      let address = document.querySelector('input[name*="address"]');
      let street = document.querySelector('input[name*="street"]');

      address = address || street;

      if (!address) {
          return;
      }

      let config = {
          container: address,
          templates: {
              value: function (suggestion) {
                  return suggestion.name;
              }
          }
      };
      if (environment.places_app_id && environment.places_app_key) {
          config.appId = environment.places_app_id;
          config.apiKey = environment.places_app_key;
      }

      let placesAutocomplete = null;
      try {
          placesAutocomplete = places(config).configure({
              type: 'address'
          });
      } catch (e) {
          console.log(e);
          return;
      }

      // properly display inline address suggestion
      if ($(address).hasClass('__inline__')) {
          $(address).removeClass('col-lg-9');
          $(placesAutocomplete.autocomplete.parent()).toggleClass('col-12 col-lg-9 p-0');
      }

      placesAutocomplete.on('change', function resultSelected(e) {
          AddressSuggestWidget.setAddress(placesAutocomplete, {
              suburb: e.suggestion.suburb,
              city: e.suggestion.city,
              state: e.suggestion.administrative,
              country: e.suggestion.countryCode,
              address: e.suggestion.name,
              postcode: e.suggestion.postcode,
          });
      });

      $(document).on('click', '.ap-icon-pin', function () {
          if (localStorage.getItem && localStorage.getItem('p')) {
              let position = JSON.parse(localStorage.getItem('p'));
              if (position) {
                  let latLng = `${Number.parseFloat(position.lat).toFixed(6)}, ${Number.parseFloat(position.lng).toFixed(6)}`;
                  algoliasearch.initPlaces(config).reverse({
                      aroundLatLng: latLng,
                      hitsPerPage: 1
                  }).then(function (e) {
                      if (e.hits.length) {
                          AddressSuggestWidget.setAddress(placesAutocomplete, {
                              suburb: e.hits[0].administrative[0],
                              city: e.hits[0].city.default[0],
                              state: e.hits[0].administrative[0],
                              country: e.hits[0].country_code,
                              address: e.hits[0].locale_names.default[0],
                              postcode: e.hits[0].postcode[0],
                          })
                      }
                  });
              }
          }
      })
  }

  static setAddress(placesAutocomplete, e) {
      let suburb = document.querySelector('input[name*="suburb"]');
      if (suburb) {
          suburb.value = e.suburb || '';
      }

      let city = document.querySelector('input[name*="city"]');
      if (city) {
          city.value = e.city || '';
      }

      let state = document.querySelector('input[name*="state"]');
      if (state) {
          state.value = e.state || '';
      }

      let $country = $('input[name*="country"]');
      if ($country.length) {
          $country.val(e.country || 'de');
      }

      let $street_no = $('input[name*="street_no"]');
      if ($street_no.length) {
          debugger;
          let house = e.address.split(' ');
          let suggestion = e.address.split(' ');
          let houseNum = Number.parseInt(house.pop());
          if (!Number.isNaN(houseNum)) {
              suggestion.pop();
              placesAutocomplete.setVal(suggestion.join(' '));
              $street_no.val(houseNum);
          }
      } else {
          placesAutocomplete.setVal(e.address);
      }

      let zip = document.querySelector('input[name*="zip"]');
      if (zip) {
          zip.value = e.postcode || '';
      }
  }
}

