(function ( $ ) {

    $.fn.btnProgress = function(action) {
        let $icon = $(this).find('i');
        let disable = $(this).data('btnProgress-disable') || false;

        /**
         * Show progress on button
         */
        if (action === 'show') {
            $(this).data('btnProgress-enable', $icon.attr('class'));
            $(this).attr('disabled', disable);

            $icon.attr('class', 'spinner-border spinner-border-sm');
        }

        /**
         * Hide progress on button
         */
        if (action === 'hide') {
            $icon.attr('class', '');
            $(this).attr('disabled', false);
        }
    };

}( jQuery ));
