export default {
    data(){
        return {
            editor: window.tinymce,
            editorConfig: null
        }
    },
    methods: {
        $_inputAction(){
            this.$_changeAction()
            this.$emit('change', this.$_value)
        }
    },
    created(){
        //$_data toolbar was undefined if declared in data()
        this.editorConfig = {
            alignment: { options: [ 'left', 'right', 'center', 'justify' ] },
            toolbar: this.$_data('toolbar'),
            table: {
                contentToolbar: [
                    'tableColumn', 'tableRow', 'mergeTableCells'
                ]
            }
        }

        setTimeout( () => {
            this.editor.init({
                selector: '#' + this.$_elKompoId + 'editor',
                branding: false,
                setup: (editor) => {
                    // init tinymce
                    editor.on('init', () =>  {
                        if (this.$_value) {
                            editor.setContent(this.$_value);
                        }
                    });

                    // when typing keyup event
                    editor.on('keyup', () => {

                        // get new value
                        var new_value = editor.getContent();

                        // set model value
                        this.component.value = new_value
                    });
                }
            });
        }, 500)
    }
}
