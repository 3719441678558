import "ion-rangeslider/js/ion.rangeSlider";
import "ion-rangeslider/css/ion.rangeSlider.css";

const SELECTOR = 'input[data-widget="rangeSlider"]';

export default class RangeSliderWidget {
  static init() {
      $(SELECTOR).each(function (i, element) {
          $(element).ionRangeSlider($(element).data('config'));
      });
  }
}
