const SELECTOR = '[data-widget="select2"]';
const SELECTOR_AJAX = '[data-widget="select2ajax"]';

export default class Select2Widget {
  static init() {
    require('select2/dist/js/select2.full');
    require('select2/dist/css/select2.css');
    require('select2/dist/js/i18n/de');
    require('select2-theme-bootstrap4/dist/select2-bootstrap.css');
    $(SELECTOR).select2({
      theme: "bootstrap",
    });

      function getCookie(name) {
          var value = "; " + document.cookie;
          var parts = value.split("; " + name + "=");
          if (parts.length == 2) return parts.pop().split(";").shift();
      }

      $(SELECTOR_AJAX).each(function (i, element) {
          let values = $(element).data('ajax--values');
          if (values && values.length) {
              values = ($(element).data('ajax--values')).replace('{', '').replace('}', '').split(',');
              values.forEach((val) =>  {
                  if (val == 'tags') {
                      return;
                  }
                  let o = new Option(val, val);
                  $(o).html(val);
                  $(o).attr('selected', true);
                  console.log($(this));
                  $(element).append(o);
              });
          }

          $(element).select2({
              theme: "bootstrap",
              minimumInputLength: 3,
              tags: $(element).data('ajax--tags'),
              allowClear: true,
              lang: 'de',
              ajax: {
                  headers: {
                      "X-Requested-With": "XMLHttpRequest",
                  },
                  data: function (params) {
                      return {
                          search: params.term,
                          token: getCookie('access_token'),
                      };
                  },
                  processResults: function (response) {
                      let id = $(element).data('ajax--id');
                      let text = $(element).data('ajax--text');

                      return {
                          results: response.data.map(function (item) {
                              return {
                                  id: item[id],
                                  text: item[text],
                              }
                          })
                      }
                  }
              }
          });
      });
  }
}
