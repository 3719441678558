import * as widget from './widget';
import List from 'list.js'
import GeoLocation from "./plugin/geolocation"

require('garlicjs/dist/garlic.min');
require('multiselect/js/jquery.multi-select');
require('multiselect/css/multi-select.css');
require('./plugin/btnProgress');
require('./plugin/serializeForm');
require('./kompo')

window.List = List;

$(document).on('pjax:success', function() {

    $('[data-persist="garlic"]').garlic();

    $(document).pjax('a:not(a[target="_blank"])', {
        container: '#pjax-container'
    });

    // remove any backdrops after reloading
    $('.modal-backdrop').remove();

    if (!$('#auth-container').length) {
        // initialize all the widgets
        $(() => {
            window.widget = widget;
            for (let i in widget) {
                widget[i].init();
            }
        });
    }

    if ($('.icheckbox').iCheck) {
        $('.icheckbox').iCheck({checkboxClass:'icheckbox_minimal-blue'});
    }

    // append back hash to each js back navigation links
    $('.js-back-href').each((i, elem) => {
        const location = window.location.pathname.replace('/', '');
        $(elem).attr('href', $(elem).attr('href') + '?_b=' + btoa(location))
    });

    // fix laravel-admin button position
    $('.column-selector').addClass('float-right');

    // show loading indicator for submit buttons
    $('form').on('submit', function () {
        let $button = $(this).find('button[type="submit"]');
        $button.btnProgress('show');
    });

    $('a[target="_new"]').attr('target', '_blank');

    // table row click navigation
    $(".table-row").click(function(event) {
        if (event.target.tagName === 'TD') {
            let $target = $(this).find('.js-edit').length ? $(this).find('.js-edit') : $(this);
            let href = $target.data("href");

            if (!href) {
                return;
            }

            let regexp = new RegExp(/.+\/company\/\d+\/edit/);
            let match = href.match(regexp);

            // reload entire page to refresh menu
            if (match && match.length && match[0] !== "") {
                window.document.location = href;
                return;
            }

            if (href) {
                $.pjax({url: href, container: '#pjax-container'});
            }
        }
    });

    // handle pjax active navigation
    let $sidebar = $('.sidebar');
    let $match = $sidebar.find(`.nav-link[href="${window.location.href}"]`);

    if ($match.length) {
        $sidebar.find('.nav-link').removeClass('active');
        $match.toggleClass('active');
    }
});

$(document).ready(function($) {

    $(document).on('click', '.aside-menu-toggler2', (e) => {
        e.stopPropagation();
        e.preventDefault();
        $('body').toggleClass('aside-menu-show');
    });


  $.admin.reload = function () {
      try {
          const urlParams = new URLSearchParams(window.location.search);
          let back = urlParams.get('_b');

          if (back.length) {
              back = atob(back);
              $.admin.redirect('https://' + window.location.host + '/' + back);
              return
          }
      } catch (e) {}

      $.pjax.reload('#pjax-container');
      $.admin.grid = new $.admin.grid.constructor;
  };

  $(document).trigger('pjax:success');
  GeoLocation.init();

  // setup global options for jquery ajax
  $.ajaxSetup({
    beforeSend: function (xhr) {
      xhr.setRequestHeader("X-CSRF-TOKEN", LA.token);
    }
  });

  $(document).on('click', 'button[type="submit"]', (event) => {
      let $button = $(event.target);
      let $form = $button.closest('form');

      // append value to the form if any
      if ($button.attr('name')) {
          $form.find(`input[name="${$button.attr('name')}"]`).remove();
          $form.append($(`<input type="hidden" name="${$button.attr('name')}" value="${$button.attr('value')}"/>`));
      }
  });
});
