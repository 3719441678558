/**
 * Sets location hash on tab click and restores
 * active tab from hash on initial load.
 */
export default class TabWidget {
    static init() {
        TabWidget.restore();

        $(document).on('click', 'a[data-toggle="tab"]', (e) => {
          let tab = $(e.target).attr('href').substr(1);
          TabWidget.rememberTab(tab);
        });

        $(document).on('click', 'button[type="submit"]', (e) => {
            if (localStorage.getItem('activeTabPath') === window.location.pathname) {
                localStorage.removeItem('activeTab');
                localStorage.removeItem('activeTabPath');
            }
        });
    }

    /**
     * Restore tab from location or localstorage
     * @return void
     */
    static restore()
    {
        if ($('.has-error').length) {
            TabWidget.openErrorTab();
        } else if (location.hash !== '') {
            TabWidget.openLocationTab();
        } else {
            TabWidget.openLocalStorageTab();
        }
    }

    /**
     * Remember tab name in local storage
     * @param tab
     * @return void
     */
    static rememberTab(tab)
    {
        tab += '_tab';
        window.location.hash = tab;
        localStorage.setItem('activeTab', tab);
        localStorage.setItem('activeTabPath', window.location.pathname);
    }

    /**
     * Open tab in location url
     * @return void
     */
    static openLocationTab()
    {
        TabWidget.showTab(
            location.hash.replace('_tab', '').replace('#', '')
        );
    }

    /**
     * Open tab stored in local storage
     * @return void
     */
    static openLocalStorageTab()
    {
        if (localStorage.getItem('activeTab')
            && localStorage.getItem('activeTabPath') === window.location.pathname
        ) {
            TabWidget.showTab(
                localStorage.getItem('activeTab').replace('_tab', '')
            );
        }
    }

    /**
     * Open first tab with error if there is any
     * @return void
     */
    static openErrorTab()
    {
      let $firstError = $('.has-error').first();
      if ($firstError.length) {
          let target = $firstError.parents('.tab-pane').attr('id');
          TabWidget.showTab(target);
      }
    }

    /**
     * Enable tab
     * @param id
     */
    static showTab(id)
    {
        if (!id) {
            return;
        }

        let section = '';
        [id, section] = id.split(':');

        let $tab = $(`a[href="#${id}"]`);

        if (!$tab.length) {
            return;
        }

        $('[data-toggle="tab"].active').toggleClass('active');
        $tab.tab('show').toggleClass('active');

        if (section) {
            setTimeout(() => {
                $('html, body').animate({
                    scrollTop: $(`#${section}`).offset().top
                }, 1000);
            }, 100);
        }
    }
}

