<template>
    <div class="gallery-item">
        <p
            v-if="previewable"
            class="thumbnail-preview"
            @click.prevent="$emit('preview', index)">
            <i class="icon-search"/>
        </p>
        <p class="thumbnail-delete" @click.stop.prevent="$emit('remove', index)">
            <i class="icon-times"/>
        </p>

        <vl-spinner-icon :loading="!image.src"/>

        <img v-if="image.src"
             :src="image.src"
             :alt="image.name"
             :style="{height: height}"
             class="gallery-image">

        <div class="mt-2 text-sm">
            {{ image.name }}
            <i class="float-right fa fa-pencil-square-o text-primary pt-1 pl-3" @click="$emit('edit', index)">
                Bearbeiten
            </i>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        image: {type: File | Object, required: true},
        index: {type: Number, required: true},
        height: {type: String, required: false},
        previewable: {type: Boolean, default: true}
    }
}
</script>
