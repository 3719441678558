const SELECTOR = '[data-widget="wysiwyg"]';

export default class WysiwygWidget {
  static init() {

      if (window.tinyMCE.get()) {
        window.tinyMCE.get().forEach(function (editor) {
          if ($(editor.targetElm).attr('id') !== 'adeditor') {
            editor.remove();
          }
        })
      }

      window.tinyMCE.init({
        selector: SELECTOR,
        height: 450,
        toolbar: 'undo redo | styleselect | bold italic backcolor forecolor | fontsizeselect | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
        menubar:false,
        fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt 48pt 56pt 64pt",
        branding: false,
        skin: false,
        content_css: false,
        setup: function (editor) {
          let element = editor.getElement();
          let settings = $(element).data('wysiwyg');
          settings = $.extend(editor.settings, settings);
          editor.settings = settings;

          //persist data on change
          editor.on("change keyup", function(e){
            editor.save();
            $(editor.getElement()).trigger('change');
          });
        }
      });
  }
}
