const SELECTOR = '[data-widget="tag"]';

export default class TagWidget {
  static init() {
    require('select2/dist/js/select2.full');
    require('select2/dist/css/select2.css');
    require('select2/dist/js/i18n/de');
    require('select2-theme-bootstrap4/dist/select2-bootstrap.css');

      $(SELECTOR).each(function (i, element) {
          let values = $(element).data('ajax--values');
          if (values && values.length) {
              values = ($(element).data('ajax--values')).replace('{', '').replace('}', '').split(',');
              values.forEach((val) =>  {
                  if (val == 'tags') {
                      return;
                  }
                  let o = new Option(val, val);
                  $(o).html(val);
                  $(o).attr('selected', true);
                  $(element).append(o);
              });
          }

          $(element).select2({
              theme: "bootstrap",
              minimumInputLength: 3,
              tags: true,
              allowClear: true,
              lang: 'de',
              insertTag: function (data, tag) {
                  let options = $(this)[0].$element.select2('data');
                  for (let i in options) {
                      if (options[i].text.toLowerCase() === tag.text.toLowerCase()) {
                          return;
                      }
                  }
                  data.unshift(tag)
              },
              ajax: {
                  headers: {
                      "X-Requested-With": "XMLHttpRequest",
                  },
                  data: function (params) {
                      return {
                          search: params.term,
                      };
                  },
                  processResults: function (response, test2, test3) {
                      let id = $(element).data('ajax--id');
                      let text = $(element).data('ajax--text');
                      let $select2 = $(this)[0].$element;

                      return {
                          results: response.data
                              .filter(function (item) {
                                  return !$select2.find(`[value="${item[id]}"]:selected`).length
                              })
                              .map(function (item) {
                                  return {
                                      id: item[id],
                                      text: item[text],
                                  }
                              })
                      }
                  }
              }
          }).on('select2:selecting', (e) => {
              // underscore tags disallowed
              if (e.params.args.data.text.indexOf('_') !== -1) {
                  e.preventDefault();
              }

              // underscore inq- tags disallowed for regular users
              // if (!window.environment.is_super && e.params.args.data.text.indexOf('inq-') !== -1) {
              //     e.preventDefault();
              // }
          })
      });
  }
}
