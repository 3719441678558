<template>
    <VlVerticalTabs
        :activeTab="activeTab"
        @tabChange="index => this.activeTab = index"
        v-bind="$_layoutWrapperAttributes"
        v-show="!$_hidden">

        <component v-bind="$_attributes(tab)"
                   v-for="(tab,index) in komponents"
                   :key="index"/>

    </VlVerticalTabs>
</template>

<script>
import Layout from 'vue-kompo/js/form/mixins/Layout'

export default {
    mixins: [Layout],
    data() {
        return {
            activeTab: 0
        }
    },
    methods: {
        $_validate(errors) {
            Layout.methods.$_validate.call(this, errors)
            this.komponents.forEach((tab, index) => {
                var errors = []
                tab.$_getErrors(errors)
                if (errors.length) {
                    this.activeTab = index
                }
            })
        }
    }

}
</script>
