const SELECTOR_FORM = '[data-widget="sweetalert2-form"]';
const SELECTOR_HREF = '[data-widget="sweetalert2-href"]';

export default class SweetAlert2FormWidget {
  static init() {
    $(SELECTOR_FORM).on('submit', function(event) {
      SweetAlert2FormWidget.handler(event, function (result) {
        if (result.value) {
          swal.fire(
            $(this).data('sweetalert2-success-title'),
            $(this).data('sweetalert2-success-message'),
            'success'
          );
          $.ajax({
            type: 'POST',
            url: $(event.target).attr('action'),
            data: $(event.target).serialize(),
            success: function () {
              window.location.reload()
            },
          });
        }
      });
    });

    $(SELECTOR_HREF).on('click', function(event) {
      SweetAlert2FormWidget.handler(event,function (result) {
        if (result.value) {
          window.location.href = $(event.target).attr('href');
        }
      });
    });
  }

  static handler(event, callback) {
    event.preventDefault();

      swal.fire({
      title: $(event.target).data('sweetalert2-text'),
      confirmButtonColor: "#DD6B55",
      showLoaderOnConfirm: true,
      type: $(event.target).data('sweetalert2-type'),
      showCancelButton: true,
      confirmButtonText: $(event.target).data('sweetalert2-confirm'),
      cancelButtonText: $(event.target).data('sweetalert2-cancel')
    }).then(callback);
  }
}
