try {
    window.Popper = require('popper.js').default;
    require('bootstrap');
    require('@coreui/coreui');
} catch (e) {}

require('@base/app.js');

require('./page/ad');
require('./page/hour');
