const SELECTOR = '[data-widget="iconpicker"]';

export default class IconPicker {
  static init() {
      $(SELECTOR).iconpicker({
          placement:'bottomLeft',
          icons: [
              'fa-tv',
              'fa-dot-circle',
              'fa-audio-description',
              'fa-money-check-alt',
              'fa-align-left',
              'fa-map',
              'fa-file-alt',
              'fa-images',
              'fa-video',
              'fa-users',
              'fa-tag',
              'fa-tags',
              'fa-clock',
              'fa-newspaper',
              'fa-dot-circle',
              'fa-dot-circle',
              'fa-money-check-alt',
              'fa-chart-area',
              'fa-glass',
              'fa-music',
              'fa-search',
              'fa-heart',
              'fa-star',
              'fa-user',
              'fa-film',
              'fa-th-large',
              'fa-th',
              'fa-th-list',
              'fa-check',
              'fa-times',
              'fa-search-plus',
              'fa-search-minus',
              'fa-power-off',
              'fa-signal',
              'fa-cog',
              'fa-home',
              'fa-clock-o',
              'fa-road',
              'fa-download',
              'fa-inbox',
              'fa-repeat',
              'fa-refresh',
              'fa-list-alt',
              'fa-lock',
              'fa-flag',
              'fa-headphones',
              'fa-volume-off',
              'fa-volume-down',
              'fa-volume-up',
              'fa-qrcode',
              'fa-barcode',
              'fa-tag',
              'fa-tags',
              'fa-book',
              'fa-bookmark',
              'fa-print',
              'fa-camera',
              'fa-font',
              'fa-bold',
              'fa-italic',
              'fa-text-height',
              'fa-text-width',
              'fa-align-left',
              'fa-align-center',
              'fa-align-right',
              'fa-align-justify',
              'fa-list',
              'fa-outdent',
              'fa-indent',
              'fa-video-camera',
              'fa-picture-o',
              'fa-pencil',
              'fa-map-marker',
              'fa-adjust',
              'fa-tint',
              'fa-pencil-square-o',
              'fa-step-backward',
              'fa-fast-backward',
              'fa-backward',
              'fa-play',
              'fa-pause',
              'fa-stop',
              'fa-forward',
              'fa-fast-forward',
              'fa-step-forward',
              'fa-eject',
              'fa-chevron-left',
              'fa-chevron-right',
              'fa-plus-circle',
              'fa-minus-circle',
              'fa-times-circle',
              'fa-check-circle',
              'fa-question-circle',
              'fa-info-circle',
              'fa-crosshairs',
              'fa-ban',
              'fa-arrow-left',
              'fa-arrow-right',
              'fa-arrow-up',
              'fa-arrow-down',
              'fa-share',
              'fa-expand',
              'fa-compress',
              'fa-plus',
              'fa-minus',
              'fa-asterisk',
              'fa-exclamation-circle',
              'fa-gift',
              'fa-leaf',
              'fa-fire',
              'fa-eye',
              'fa-eye-slash',
              'fa-exclamation-triangle',
              'fa-plane',
              'fa-calendar',
              'fa-random',
              'fa-comment',
              'fa-magnet',
              'fa-chevron-up',
              'fa-chevron-down',
              'fa-retweet',
              'fa-shopping-cart',
              'fa-folder',
              'fa-folder-open',
              'fa-bar-chart',
              'fa-camera-retro',
              'fa-key',
              'fa-cogs',
              'fa-comments',
              'fa-star-half',
              'fa-thumb-tack',
              'fa-trophy',
              'fa-upload',
              'fa-lemon-o',
              'fa-phone',
              'fa-phone-square',
          ],
      });
  }
}
