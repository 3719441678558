import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.standalone.css';

const SELECTOR = 'input[data-widget="datepicker"]';

export default class DatePickerWidget {
  static init() {
    require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.en-GB.min');
    require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.de.min');

    $(SELECTOR).each(function (i, element) {
      let config = {
        todayHighlight: true,
        format: 'dd.mm.yyyy',
        orientation: "auto",
        container: '.container-fluid',
        autoclose: true,
        toggleActive: true,
        language: document.documentElement.lang,
      };

      let startDate = $(element).attr('data-datepicker--startDate');
      if (startDate) {
        config["startDate"] = startDate;
      }

      $(element).datepicker(config);
        $(element).on('show', function (e) {
            $(e.target).data('datepicker').picker.find('.icon-arrow-right').attr('class', 'fa fa-chevron-right');
            $(e.target).data('datepicker').picker.find('.icon-arrow-left').attr('class', 'fa fa-chevron-left');
        });
      $(element).attr('autocomplete', "off");
    });
  }
}
