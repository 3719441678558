<template>
    <section :id="id">
        <div class="h5 pr-3 pl-3 pt-2 pb-2">
            <span v-html="icon"></span>
            <span>{{ title }}</span>
            <hr/>
        </div>
        <div class="col-md-12">
            <component
                v-bind="$_layoutWrapperAttributes"
                v-show="!$_hidden"
                @click="$_clickAction">
                <component v-for="(col,index) in komponents"
                           :key="index"
                           v-bind="$_attributes(col)" />
            </component>
        </div>
    </section>
</template>

<script>
import Layout from "vue-kompo/js/form/mixins/Layout";

export default {
    mixins: [Layout],
    props: {
        id: { default: null },
    },
    computed:{
        title(){
            return this.$_data('attrs.title');
        },
        icon(){
            return this.$_data('icon');
        },
        $_customClassArray(){
            return [
                this.$_data('justifyClass'),
                this.$_data('alignClass')
            ]
        },
        $_customLayoutAttributes(){
            return this.$_layoutHrefAttributes
        }
    }
};
</script>
