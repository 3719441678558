<template>
    <div class="modal-content fm-modal-rename" ref="modal">
        <div class="modal-header">
            <h5 class="modal-title">{{ lang.modal.rename.title }}</h5>
            <a type="button" class="close" aria-label="Close" v-on:click="hideModal">
                <span aria-hidden="true">&times;</span>
            </a>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label for="fm-input-rename">{{ lang.modal.rename.fieldName }}</label>
                <input type="text" class="form-control" id="fm-input-rename"
                       v-focus
                       v-bind:class="{'is-invalid': checkName}"
                       v-model="name"
                       v-on:keyup="validateName">
                <div class="invalid-feedback" v-show="checkName">
                    {{ lang.modal.rename.fieldFeedback }}
                    {{ directoryExist ? ` - ${lang.modal.rename.directoryExist}` : ''}}
                    {{ fileExist ? ` - ${lang.modal.rename.fileExist}` : ''}}
                </div>
            </div>
            <div class="form-group" v-show="checkTags">
                <label for="fm-input-tags">Tags</label>
                <select name="tags"
                        id="fm-input-tags"
                        ref="tags"
                        v-focus
                        v-model="tags"
                        @change="console.log(this.value);"
                        multiple
                        data-ajax--url="/api/v1/tag"
                        data-ajax--id="name"
                        data-ajax--text="name"
                >
                    <option  v-for="tag in initialTags" :key="tag" :value="tag" selected>{{ tag }}</option>
                </select>
            </div>
        </div>
        <div class="modal-footer">
            <a class="btn btn-info"
                    v-bind:disabled="submitDisable"
                    v-on:click="rename">{{ lang.btn.submit }}

            </a>
            <a class="btn btn-light" v-on:click="hideModal">{{ lang.btn.cancel }}</a>
        </div>
    </div>
</template>

<script>
import modal from '../mixins/modal';
import translate from '../../../mixins/translate';

export default {
  name: 'Rename',
  mixins: [modal, translate],
  data() {
    return {
      name: '',
      initialTags: [],
      tags: [],
      directoryExist: false,
      fileExist: false,
    };
  },
  computed: {
    /**
     * Selected item
     * @returns {*}
     */
    selectedItem() {
      return this.$store.getters[`fm/${this.activeManager}/selectedList`][0];
    },

    /**
     * Check new name
     * @returns {boolean}
     */
    checkName() {
      return this.directoryExist || this.fileExist || !this.name;
    },

    checkTags() {
      return this.selectedItem.type !== 'dir'
    },

    /**
     * Submit button disable
     * @returns {*|boolean}
     */
    submitDisable() {
      return this.checkName || this.name === this.selectedItem.basename;
    },
  },
  mounted() {
    // initiate item name
    this.name = this.selectedItem.basename;
    this.initialTags = this.selectedItem.tags || [];
    this.tags = this.selectedItem.tags ?? [];
    $(this.$refs.tags).select2({
        dropdownParent: $(this.$refs.modal),
        theme: "bootstrap",
        minimumInputLength: 3,
        tags: true,
        allowClear: true,
        lang: 'de',
        insertTag: function (data, tag) {
            let options = $(this)[0].$element.select2('data');
            for (let i in options) {
                if (options[i].text.toLowerCase() === tag.text.toLowerCase()) {
                    return;
                }
            }
            data.unshift(tag)
        },
        ajax: {
            headers: {
                "X-Requested-With": "XMLHttpRequest",
            },
            data: function (params) {
                return {
                    search: params.term,
                };
            },
            processResults: function (response, test2, test3) {
                let id = $(this)[0].$element.data('ajax--id');
                let text = $(this)[0].$element.data('ajax--text');
                let $select2 = $(this)[0].$element;

                return {
                    results: response.data
                        .filter(function (item) {
                            return !$select2.find(`[value="${item[id]}"]:selected`).length
                        })
                        .map(function (item) {
                            return {
                                id: item[id],
                                text: item[text],
                            }
                        })
                }
            }
        }
    }).on("select2:select", (e) => {
        let tags = [];
        $(e.target).select2('data').forEach(function (item) {
            tags.push(item.id);
        });
        this.tags = tags;
    });
  },
  methods: {
    /**
     * Validate item name
     */
    validateName() {
      if (this.name !== this.selectedItem.basename) {
        // if item - folder
        if (this.selectedItem.type === 'dir') {
          // check folder name matches
          this.directoryExist = this.$store.getters[`fm/${this.activeManager}/directoryExist`](this.name);
        } else {
          // check file name matches
          this.fileExist = this.$store.getters[`fm/${this.activeManager}/fileExist`](this.name);
        }
      }
    },

    /**
     * Rename item
     */
    rename() {
      // create new name with path
      const newName = this.name;

      this.$store.dispatch('fm/renameWithTags', {
        type: this.selectedItem.type,
        newName,
        oldName: this.selectedItem.path,
        tags: this.tags,
      }).then(() => {
        // close modal window
        this.hideModal();
      });
    },
  },
};
</script>
