import Bloodhound from "typeahead.js/dist/bloodhound";

const SELECTOR_SEARCH = '[data-widget="typeahead-search"]';

export default class TypeaheadWidget {
  static init() {
    /** @see https://github.com/twitter/typeahead.js/issues/1627#issuecomment-497624244 */
    require("imports-loader?exports=>false,define=>false!typeahead.js/dist/typeahead.bundle.js");

    $(SELECTOR_SEARCH).each(function (i, element) {
      let suggestions = $(element).data('suggestion');

      suggestions = suggestions.map(function (suggestion) {
        return {
          name: Math.random().toString(36).substring(2, 15),
          display: suggestion.display,
          source: new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('id'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            prefetch: suggestion.prefetch,
            remote: {
              url: decodeURIComponent(suggestion.remote),
              wildcard: ':query',
              transform: function (response) {
                if (suggestion.href) {
                  return response.data.map(function (item) {
                    item.href = suggestion.href.replace(':id', item.id);
                    if (suggestion.map) {
                      for (let key in Object.keys(suggestion.map)) {
                        item.href = item.href.replace(key, item[suggestion.map[key]]);
                      }
                    }
                    return item;
                  });
                }
                return response.data;
              }
            },
          }),
          templates: {
            header: suggestion.group ? `<h1 class="twitter-typeahead-group">${suggestion.group}</h1>` : null,
          }
        };
      });

      $(element).typeahead({
          highlight: true
        },
        ...suggestions
      ).on('typeahead:select', function (e, selection) {
        if (selection.href) {
          event.preventDefault();
          window.location = selection.href;
        }
      })
    });
  }
}
