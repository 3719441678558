export default class AdLocation
{
    constructor(elementId, locations, settings = {}) {
        if (!document.getElementById(elementId)) {
            return;
        }

        this.map = new google.maps.Map(document.getElementById(elementId), {
            zoom: 15,
            center: new google.maps.LatLng(0, 0),
        });
        this.infoWindow = new google.maps.InfoWindow();
        this.current = null;
        this.positions = [];
        this.settings = settings;

        locations.forEach((location) => {
            this.drawLocation(location)
        });
    }

    fitBounds() {
        if (this.positions.length === 1) {
            this.map.setCenter(this.positions[0].location);
            this.map.fitBounds(this.current.circle.getBounds());
            return;
        }

        let bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < this.positions.length; i++) {
            bounds.extend(this.positions[i].location);
        }
        this.map.fitBounds(bounds);
    }

    removeCurrentLocation() {
        if (this.positions.length <= 1) {
            if (this.settings.onError) {
                this.settings.onError("Es sollte mindestens ein Ort festgelegt werden")
            }
            return;
        }

        this.positions.forEach((position, index) => {
            if (
                position.location.lat() == this.current.location.lat()
                && position.location.lng() == this.current.location.lng()
            ) {
                this.positions.splice(index, 1);
                return;
            }
        });

        this.current.marker.setMap(null);
        this.current.circle.setMap(null);

        this.setCurrent(this.positions[0]);
    }

    updateCurrentLocation(location) {
        if (location.lat && location.lon) {
            let latLng = new google.maps.LatLng(Number.parseFloat(location.lat), Number.parseFloat(location.lon));
            this.current.marker.setPosition(latLng);
            this.current.location = latLng;
        }

        this.current.circle.setMap(null);
        this.current.circle = this.drawCircle(location.radius, this.current.location);

        if (this.settings.onChange) {
            this.settings.onChange(this.current, this.positions)
        }

        this.fitBounds();
    }

    setCurrent(position) {
        this.current = position;
        this.infoWindow.setContent('Momentan ausgewählt');
        this.infoWindow.open(this.map, position.marker);

        if (this.settings.onChange) {
            this.settings.onChange(this.current, this.positions)
        }
    }

    drawLocation(location, init = false) {
        let latLng = new google.maps.LatLng(Number.parseFloat(location.lat), Number.parseFloat(location.lon));

        // skip duplicated coordinates
        for(let index in this.positions) {
            if (
                this.positions[index].location.lat() == latLng.lat()
                && this.positions[index].location.lng() == latLng.lng()
            ) {
                if (this.settings.onError) {
                    this.settings.onError("Dieser Ort existiert bereits")
                }
                return;
            }
        }

        let newPosition = {
            location: latLng,
            marker: this.drawMaker(latLng),
            circle: this.drawCircle(location.radius, latLng),
        };

        this.positions.push(newPosition);

        if (!init || !this.current) {
            this.setCurrent(newPosition);
            if (this.settings.onSelect) {
                this.settings.onSelect(newPosition);
            }
        }

        this.fitBounds();
    }

    drawCircle(radius, latLng)
    {
        location.radius *= 1;
        let options = {
            strokeColor: "#0000FF",
            strokeOpacity: 0.35,
            strokeWeight: 2,
            fillColor: "#0000FF",
            fillOpacity: 0.20
        };
        options.map = this.map;
        options.radius = radius;
        options.center = latLng;

        return new google.maps.Circle(options);
    }

    drawMaker(latLng) {
        let marker =  new google.maps.Marker({
            position: latLng,
            map: this.map,
            title: "Position " + (this.positions.length + 1),
            visible: true,
            draggable: false,
            icon: undefined
        });

        marker.addListener('click', () => {
            this.positions.forEach((position) => {
                if (
                    position.location.lat() == marker.position.lat()
                    && position.location.lng() == marker.position.lng()
                ) {
                    this.setCurrent(position);
                    if (this.settings.onSelect) {
                        this.settings.onSelect(position);
                    }
                }
            })
        });

        return marker
    }
}
